// import node modules
import React, { Component } from "react";
import ImageCard from '../../../components/ImageCard/ImageCard.component';
import CollapseComponent from '../../../components/Collapse/Collapse.component';
import { Container, Row} from "reactstrap";
import SEO from "../../../components/seo";

// import constant files
import {
RCP_PAGE_HEADER_TEXT,
/* RCP_PAGE_PARA_TEXT, */
RCP_SW_HEADER_TEXT,
RCP_PZ_HEADER_TEXT,
RCP_ES_HEADER_TEXT,
RCP_APT_HEADER_TEXT
} from "../../../constants/views/Recipes/RecipeGroups.constant";

class RecipeGroups extends Component { 

 constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  
  
  render() {

    const sandwich = this.props.recipes.sandwiches;
    const pizza = this.props.recipes.pizzas;
    const entrees = this.props.recipes.entrees;
    const appetizers = this.props.recipes.appetizers;

    /* console.log(this.state.width);
    console.log(this.state.height); */
    let first_counter_limit = 2;
    //console.log(this.state.width);
    let items_to_show = 3;
    if(this.state.width < 768)
    {
      first_counter_limit = 1;
      items_to_show = 2;
    }
    let second_counter_limit = first_counter_limit + items_to_show;
    //console.log(first_counter_limit);
    return (
      <React.Fragment>
        <SEO title={this.props.recipeMetaTitle} description={this.props.recipeMetaDescription} />
        <section
          className="gagl-rgroup-wrapper gagl-recipe-bg-color gagl-nav-top-space"
        >        
          <Container className="gagl-rgroup-container">
              <h1 className="gagl-rgroup-main-header-text ">{RCP_PAGE_HEADER_TEXT}</h1>
              <p className="gagl-rgroup-para-text ">Perfect for everyday meals, snacking, or entertaining, Galileo<sup className="gagl-rgroup-sup-text">®</sup> Salame and Pepperoni are the perfect addition to a wide variety of tried and true recipes.</p>
          </Container>
          <section className="gagl-rgroup-rcp-content" >
            <h2 className="gagl-rgroup-header-text">{RCP_SW_HEADER_TEXT}</h2>        
            <Container className="gagl-rgroup-container">
              <Row>
                {                
                  sandwich.map((value, index) => {                  
                    if(index <= first_counter_limit)
                      return <ImageCard 
                        itemTextClass="gagl-rgroup-item-text" 
                        imgClass="gagl-rgroup-img-small" 
                        key={value.slug} 
                        imgSrc={value.image} 
                        alt={value.alt_text} 
                        itemText={value.name}
                        slug={value.slug}
                        type="recipes"
                        item=" "></ImageCard>
                    else
                      return '';
                    })
                }
                
              </Row>          
              <CollapseComponent
                  recipes={this.props.recipes}
                  class="gagl-pr-collapse-block"  
                  type="recipes" 
                  group="sandwiches" 
                  length={sandwich.length}
                  itemTextClass="gagl-rgroup-item-text" 
                  imgClass="gagl-rgroup-img-small"
                  btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
                  start={first_counter_limit}
                  end={second_counter_limit}
                  item=" "></CollapseComponent>
            </Container>

            <h2 className="gagl-rgroup-header-text ">{RCP_PZ_HEADER_TEXT}</h2>
            <Container className="gagl-rgroup-container">
              <Row>  
              {
                  pizza.map((value, index) => {                  
                    if(index <= first_counter_limit)
                      return <ImageCard 
                      itemTextClass="gagl-rgroup-item-text" 
                      imgClass="gagl-rgroup-img-small" 
                      key={value.slug} 
                      imgSrc={value.image} alt={value.alt_text} 
                      itemText={value.name}
                      slug={value.slug}
                      type="recipes"
                      item=" "></ImageCard>
                    else
                      return '';
                    })
                }
                
              </Row>
              <CollapseComponent
                  recipes={this.props.recipes}
                  class="gagl-pr-collapse-block"  
                  type="recipes" 
                  group="pizzas" 
                  length={pizza.length}
                  itemTextClass="gagl-rgroup-item-text" 
                  imgClass="gagl-rgroup-img-small"
                  btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
                  start={first_counter_limit}
                  end={second_counter_limit}
                  item=" "></CollapseComponent>
            </Container>

            <h2 className="gagl-rgroup-header-text ">{RCP_APT_HEADER_TEXT}</h2>
            <Container className="gagl-rgroup-container">
              <Row>  
              {
                  appetizers.map((value, index) => {                  
                    if(index <= first_counter_limit)
                      return <ImageCard 
                      itemTextClass="gagl-rgroup-item-text" 
                      imgClass="gagl-rgroup-img-small" 
                      key={value.slug} 
                      imgSrc={value.image} alt={value.alt_text} 
                      itemText={value.name}
                      slug={value.slug}
                      type="recipes"
                      item=" "></ImageCard>
                    else
                      return '';
                    })
                }
                
              </Row>
              <CollapseComponent
                  recipes={this.props.recipes}
                  class="gagl-pr-collapse-block"  
                  type="recipes" 
                  group="appetizers" 
                  length={appetizers.length}
                  itemTextClass="gagl-rgroup-item-text" 
                  imgClass="gagl-rgroup-img-small"
                  btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
                  start={first_counter_limit}
                  end={second_counter_limit}
                  item=" "></CollapseComponent>
            </Container>

            <h2 className="gagl-rgroup-header-text ">{RCP_ES_HEADER_TEXT}</h2>
            <Container className="gagl-rgroup-container">
              <Row>  
              {
                  entrees.map((value, index) => {                  
                    if(index <= first_counter_limit)
                      return <ImageCard 
                      itemTextClass="gagl-rgroup-item-text" 
                      imgClass="gagl-rgroup-img-small" 
                      key={value.slug} 
                      imgSrc={value.image} alt={value.alt_text} 
                      itemText={value.name}
                      slug={value.slug}
                      type="recipes"
                      item=" "></ImageCard>
                    else
                      return '';
                    })
                }
                
              </Row>
              <CollapseComponent
                  recipes={this.props.recipes}
                  class="gagl-pr-collapse-block"  
                  type="recipes" 
                  group="entrees" 
                  length={entrees.length}
                  itemTextClass="gagl-rgroup-item-text" 
                  imgClass="gagl-rgroup-img-small"
                  btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
                  start={first_counter_limit}
                  end={second_counter_limit}
                  item=" "></CollapseComponent>
            </Container>          
          </section>
            
        </section>
      </React.Fragment>
    );
  }
}

export default RecipeGroups;
