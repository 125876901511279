import React from "react";
import { Link } from "gatsby";

export const RECIPE_DETAILS_DATA = {
  "italian-sandwich": {
    recipeName: "italian classico sandwich",
    metaTitle: "Italian Classico Sandwich Recipe | Galileo® Salame",
    metaDescription:
      "A fresh take on a classic favorite. Make lunch time even tastier with our Italian classico sandwich recipe!",
    recipeDescription: (
      <span>
        Savory salame and pepperoni, crisp vegetables, bright vinaigrette served
        up on sourdough bread. A sandwich that is sure delight any day, any
        time.
      </span>
    ),
    recipeIngredients: [
      <span>2 slices of sourdough bread</span>,
      <span>1 oz. Italian vinaigrette</span>,
      <span>
        2 slices Provolone or Swiss cheese sliced meats (1.5 oz. each of
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp; Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        ,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>
        , and turkey)
      </span>,
      <span>1/4 cup iceberg lettuce, shredded</span>,
      <span>4 to 6 pickle slices</span>,
      <span>2 to 3 tomato slices</span>,
      <span>handful sliced black or green olives, optional</span>,
    ],
    recipeDirections: [
      <span>
        Lightly drizzle the vinaigrette on one side of both pieces of bread.
      </span>,
      <span>
        On the dressed side of one bread slice, layer cheese, 
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        ,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>
        , turkey, and veggies.
      </span>,
      <span>
        Top with the second bread slice, dressed side down, cut sandwich in half
        and enjoy!{" "}
      </span>,
    ],
    more_recipe: [
      "pepperoni-hoagie",
      "salame-grilled-cheese",
      "salame-cheese-croissant-sandwich",
      "italian-bean-salad",
    ],
  },
  "pepperoni-hoagie": {
    recipeName: "pepperoni hoagie",
    metaTitle: "Pepperoni Hoagie Recipe | Galileo® Salame",
    metaDescription:
      "A savory and zesty hoagie perfect for the big game or a tasty lunch. Our Galileo® Pepperoni is sure to be a hit!",
    recipeDescription: (
      <span>
        A hoagie with Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni is perfect for the big game
        or enjoyed all by yourself! Savory, zesty, and full of flavor.
      </span>
    ),
    recipeIngredients: [
      <span>12-inch Italian loaf</span>,
      <span>olive oil, for drizzling</span>,
      <span>balsamic vinegar, for drizzling</span>,
      <span>1/2 cup baby spinach</span>,
      <span>1/4 cup Italian mix giardiniera, chopped</span>,
      <span>1/4 cup banana peppers, sliced</span>,
      <span>
        10 to 15
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp; Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>2 to 3 slices Provolone cheese</span>,
    ],
    recipeDirections: [
      <span>
        Cut Italian loaf bread down the middle, and drizzle with olive oil and
        balsamic vinegar.
      </span>,
      <span>
        Place a layer of baby spinach and sprinkle Italian mix giardiniera on
        top.
      </span>,
      <span>
        Then layer Provolone slices,
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices, and banana peppers.
      </span>,
      <span>
        Drizzle with more balsamic and olive oil, and top with the remaining
        Italian mix giardiniera.
      </span>,
    ],
    more_recipe: [
      "italian-sandwich",
      "italian-pinwheels",
      "pepperoni-cheese-bread",
      "pepperoni-mixed-greens-salad",
    ],
  },
  "pepperoni-cheese-bread": {
    recipeName: "pepperoni and cheese bread",
    metaTitle: "Pepperoni & Cheese Bread Recipe | Galileo® Salame",
    metaDescription:
      "Great as a snack or a side dish, this pepperoni and cheese bread is a delicious combination of gooey cheese and delicious pepperoni!",
    recipeDescription: (
      <span>
        This snack favorite pops with flavor perfect for game day, movie night,
        or a tasty treat on a typical Tuesday.
      </span>
    ),
    recipeIngredients: [      
      <span>1 tbsp. olive oil</span>,
      <span>3 lbs. frozen bread dough, thawed</span>,
      <span>1/4 cup fresh basil, chopped</span>,
      <span>1 cup mozzarella cheese, shredded</span>,
      <span>1/2 cup Parmesan cheese, freshly grated</span>,
      <span>
        1-7oz pack of sliced
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
      </span>,
      <span>1/2 cup sundried tomatoes, drained</span>,
      <span>4 garlic cloves, minced</span>,
    ],
    recipeDirections: [
      <span>
        Spread olive oil in bottom of a jelly roll pan and press dough into pan,
        stretching to cover bottom of entire pan.
      </span>,
      <span>
        Press half of the basil into the dough. Let stand in a warm, draft-free
        place for 30 minutes or until doubled in size.
      </span>,
      <span>
        Preheat oven to 400°F. Sprinkle dough with both cheeses. Lay
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        in rows over cheese, overlapping slightly, and top with sundried
        tomatoes and minced garlic.
      </span>,
      <span>
        Bake on bottom rack for 15 to 20 minutes or until surface is golden
        brown. Remove from oven and sprinkle with remaining basil.
      </span>,
    ],
    more_recipe: [
      "pepperoni-flatbread-pizza",
      "pepperoni-pizza",
      "pepperoni-rolls",
      "meat-lovers-calzone",
    ],
  },
  "meat-lovers-calzone": {
    recipeName: "meat lovers calzone",
    metaTitle: "Meat Lovers Calzone Recipe | Galileo® Salame",
    metaDescription:
      "Packed with bold flavor in every bite, our calzone is stuffed with delicious salame, pepperoni and Italian sausage. Perfect for every meat lover in your family!",
    recipeDescription: (
      <span>
        A flavorful new take on a classic Italian dish, these calzones packed
        with Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame and Pepperoni are the next best thing to
        pizza!
      </span>
    ),
    recipeIngredients: [
      <span>1 lb. refrigerated pizza dough</span>,
      <span>4 oz. ground Italian sausage</span>,
      <span>
        2 oz.
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>
        2 oz.
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, cut into quarters
      </span>,
      <span>1/2 cup marinara sauce</span>,
      <span>2 cups shredded mozzarella cheese</span>,
      <span>2 tbsp. butter, melted</span>,
      <span>1 tsp. Italian seasoning</span>,
    ],
    recipeDirections: [
      <span>Preheat oven to 400 degrees. Grease sheet pan and set aside.</span>,
      <span>
        In a medium skillet, cook Italian sausage over medium heat until cooked
        thoroughly, about 7 minutes. Once cooked, add marinara sauce,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        quarters, and
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices, mix and set aside.
      </span>,
      <span>
        On a lightly floured surface, roll out the dough to about 1/8 inch
        thickness and cut into 4 circles. Place meat filling into each piece of
        dough, top with shredded mozzarella and fold over, crimp the outside
        together by pressing with a fork around the edges.
      </span>,
      <span>
        Place in oven and bake until pizza crust is cooked, anywhere from 15 to
        20 minutes.
      </span>,
      <span>
        Remove from oven and brush with butter, sprinkle with Italian seasoning
        and serve.
      </span>,
    ],
    more_recipe: [
      "salame-calzone",
      "pepperoni-pizza",
      "pepperoni-flatbread-pizza",
      "italian-salame-bean-soup",
    ],
  },
  "cheesy-salame-pepperoni-pasta-bake": {
    recipeName: "cheesy salame and pepperoni pasta bake",
    metaTitle: "Cheesy Salame & Pepperoni Pasta Bake Recipe | Galileo® Salame",
    metaDescription:
      "Filled with gooey cheesy flavor and delicious Galileo® Salame and Pepperoni, this pasta bake is the perfect dinner any night of the week.",
    recipeDescription: (
      <span>
        This pepperoni and salame bake pasta is equal amounts saucy and cheesy
        with just the right pop of fresh basil. Make tonight a pasta bake night.
      </span>
    ),
    recipeIngredients: [
      <span>Non-stick cooking spray</span>,
      <span>1 lb. rotini pasta</span>,
      <span>3 cups marinara sauce</span>,
      <span>
        4 oz.
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>
        4 oz.
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,      
      <span>salt and pepper</span>,
      <span>3 cups shredded Mozzarella cheese, divided</span>,
      <span>1 cup shredded Parmesan cheese, divided into ½ cups</span>,
      <span>fresh basil for garnish</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 375 degrees, spray a medium oven safe casserole dish
        with non-stick spray.
      </span>,
      <span>
        Boil pasta according to package instructions, drain and place in large
        bowl and mix with 1/2 cup parmesan, 2 cups mozzarella,
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices and sauce. Season to taste with salt and pepper.
      </span>,
      <span>
        Top with remaining parmesan and mozzarella cheese and bake for 20
        minutes or until sauce is bubbly and cheese is melted.
      </span>,
      <span>Top with fresh basil and serve.</span>,
    ],
    more_recipe: [
      "pizza-monkey-bread",
      "hot-pizza-dip-garlic-crostini",
      "meat-lovers-calzone",
      "italian-salame-bean-soup",
    ],
  },
  "salame-calzone": {
    recipeName: "salame calzone",
    metaTitle: "Salame Calzone Recipe | Galileo® Salame",
    metaDescription:
      "All the great flavor packed into one calzone. Our Galileo Salame® Calzone is bursting with cheesy and flaky goodness.",
    recipeDescription: (
      <span>
        A delicious dish bursting with flavor. This crispy, flaky, and
        delightfully robust snack will make this recipe a weekly go-to.
      </span>
    ),
    recipeIngredients: [
      <span>1 lb. refrigerated pizza dough</span>,
      <span>1/4 cup onion, finely chopped</span>,
      <span>1/4 cup minced garlic</span>,
      <span>
        1 cup
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, shredded ¼ inch thick
      </span>,
      <span>2 cups ricotta cheese</span>,      
      <span>1 cup Parmesan cheese, grated</span>,
      <span>1 cup fresh tomato, chopped</span>,
      <span>6 large basil leaves, chopped</span>,      
      <span>salt and pepper</span>,
      <span>2 tbsp. olive oil</span>,
    ],
    recipeDirections: [
      <span>
        Remove the dough from the refrigerator and let sit at room temperature
        for 5-10 minutes.
      </span>,
      <span>
        In the meantime, sauté onions and garlic in olive oil until translucent,
        then add tomato and sauté 1 additional minute. Remove from heat and add
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        shreds, cheeses, basil, salt & pepper and olive oil to form filling.
      </span>,
      <span>
        On a lightly floured surface, roll out the dough to about 1/8-inch
        thickness and cut into 4 large circles.
      </span>,
      <span>
        Preheat oven to 350°. Add 2 tablespoons filling to each piece of round dough. Moisten the edges with water, then fold over to enclose the filling, and press down to seal.
      </span>,
      <span>
        Bake the calzones on a greased baking sheet for 25-35 minutes, until
        golden brown on the outside.
      </span>,
    ],
    more_recipe: [
      "meat-lovers-calzone",
      "pepperoni-cheese-bread",
      "salame-cheese-croissant-sandwich",
      "salame-grilled-cheese",
    ],
  },
  "hot-pizza-dip-garlic-crostini": {
    recipeName: "hot pizza dip with garlic crostini",
    metaTitle: "Hot Pizza Dip with Garlic Crostini Recipe | Galileo® Salame",
    metaDescription:
      "One bite of our cheesy hot pizza dip recipe and you'll have never go back to those boring old dips.",
    recipeDescription: (
      <span>
        One bite of this dip and the rest is history. The tang of our salame and
        pepperoni with the creamy cheese will have everyone at the table asking
        for more.
      </span>
    ),
    recipeIngredients: [
      <span>1 baguette</span>,      
      <span>2 tbsp. olive oil</span>,
      <span>2 oz. butter, melted</span>,
      <span>1 tsp. garlic powder</span>,
      <span>8 oz. cream cheese at room temperature</span>,
      <span>1 cup marinara</span>,
      <span>2 cups shredded Mozzarella cheese</span>,
      <span>
        15 to 20
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>
        15 to 20
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, cut into quarters
      </span>,
      <span>1/2 cup finely shredded Parmesan cheese</span>,
      <span>1 tsp. Italian seasoning</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 375 degrees. Cut baguette into thin slices, lay out on
        baking sheet(s). Mix olive oil, melted butter, and garlic powder. Brush
        front and back of each slice of bread and set aside.
      </span>,
      <span>
        In a shallow baking dish or pie dish, brush remaining butter and olive
        oil mixture over surface. Layer in cream cheese followed by 1 cup
        mozzarella cheese and then marinara sauce. Spread marinara evenly with
        spoon or spatula.
      </span>,
      <span>
        Sprinkle the remaining cup of mozzarella, then top with
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices and
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices. Finish with shredded parmesan and Italian seasoning.
      </span>,
      <span>
        Bake for 25 to 30 minutes until cheese is melted and sauce is bubbling
        around the outer edges. Remove from oven and set aside to cool.
      </span>,
      <span>
        Turn oven temperature up to 425 degrees. Bake bread for 5 to 7 minutes
        until crispy. Remove from oven and serve with dip.
      </span>,
    ],
    more_recipe: [
      "pizza-monkey-bread",
      "pepperoni-rolls",
      "pepperoni-cheese-bread",
      "pepperoni-flatbread-pizza",
    ],
  },
  "salame-pepperoni-charcuterie-plate": {
    recipeName: "salame and pepperoni charcuterie plate",
    metaTitle: "Salame & Pepperoni Charcuterie Plate Recipe | Galileo® Salame",
    metaDescription:
      "Our charcuterie plate recipe made with Galileo® Salame and Pepperoni is perfect for sharing with friends or keeping it all for yourself.",
    recipeDescription: (
      <span>
        Whether it's a small gathering of friends or a cozy night at home, this
        charcuterie plate made with Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame and Pepperoni
        makes the most satisfying snack.
      </span>
    ),
    recipeIngredients: [
      <span>2 slices Provolone cheese, cut in half</span>,
      <span>1-2 oz. piece of Parmesan cheese, cut in half</span>,
      <span>
        4
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,
      <span>
        6
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>1 small bunch of red seedless grapes</span>,
      <span>4 cured olives</span>,
      <span>2 strips grilled sweet peppers, room temperature</span>,
      <span>4 slices crostini or crackers</span>,
      <span>2 thin breadsticks, optional</span>,
    ],
    recipeDirections: [
      <span>
        Attractively arrange cheese,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices and
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices on a small plate or board.
      </span>,
      <span>Fill in spaces with the breads, grapes, peppers and olives.</span>,
      <span>Finish with the breadsticks.</span>,
      <span>Feeding a crowd? This recipe scales up easily – just double or triple the quantities to fit your group size.</span>
    ],
    more_recipe: [
      "salame-chips-honey-mustard-dip",
      "italian-pinwheels",
      "italian-bean-salad",
      "salame-pasta-salad",
    ],
  },
  "salame-chips-honey-mustard-dip": {
    recipeName: "baked salame chips with honey mustard dip",
    metaTitle: "Salame Chips with Honey Mustard Dip Recipe | Galileo® Salame",
    metaDescription:
      "A new take on chips and dip. Our salame chips paired with honey mustard dip will have you wondering why you didn’t try them sooner.",
    recipeDescription: (
      <span>
        Break from the norm with a new take on chips and dip. Our tasty chips made from Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame paired with a creamy honey mustard dip makes the perfect afternoon snack or party appetizer!
      </span>
    ),
    recipeIngredients: [
      <span>
        1-7 oz. pack of thin sliced
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>
      </span>,
      <span>1/3 cup Dijon mustard</span>,
      <span>1/4 cup mayonnaise</span>,
      <span>1/4 cup honey</span>,
      <span>1 tbsp. white distilled vinegar</span>,
      <span>salt and pepper</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 350 degrees. Line two baking sheets with parchment paper
        and lay out
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices with 1/4 inch between each piece.
      </span>,
      <span>Bake for 10 to 12 minutes or until the edges curl.</span>,
      <span>
        While salame is baking, whisk remaining ingredients together in a small
        bowl and season to taste.
      </span>,
      <span>
        Remove salame from oven and lay on paper towels to cool for 10 minutes.
        They will crisp up as they cool.
      </span>,
      <span>
        Arrange the salame chips in a bowl or on a plate and serve with the
        honey mustard dip alongside.
      </span>,
    ],
    more_recipe: [
      "antipasto-skewers",
      "pizza-monkey-bread",
      "hot-pizza-dip-garlic-crostini",
      "salame-pepperoni-charcuterie-plate",
    ],
  },
  "italian-pinwheels": {
    recipeName: "italian pinwheels",
    metaTitle: "Italian Pinwheels Recipe | Galileo® Salame",
    metaDescription:
      "This festive pinwheel recipe made with Galileo® Salame and Pepperoni is certain to be a big hit at the next gathering with everyone asking how you made them.",
    recipeDescription: (
      <span>
        Bursting with flavor, these zesty pinwheels made with Galileo
        <sup className="gagl-rcp-sup-text">®</sup> Salame and Pepperoni are perfect for a weekday lunch or
        your next potluck! A new bold take on an old favorite.
      </span>
    ),
    recipeIngredients: [
      <span>3 large flour tortillas, burrito size</span>,
      <span>1 cup cream cheese, softened</span>,
      <span>1 tbsp. Italian seasoning</span>,
      <span>
        1-6 oz. jar roasted red peppers slices, drained and dried on paper
        towels
      </span>,
      <span>
        1-7 oz. pack of thin sliced
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>
      </span>,
      <span>
        1-7 oz. pack of sliced
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
      </span>,      
      <span>1 1/2 cups baby spinach</span>,
    ],
    recipeDirections: [
      <span>
        Spread a third of the cream cheese evenly over each tortilla, then
        sprinkle with 1 tsp. Italian seasoning.
      </span>,
      <span>
        Place a third of the roasted red peppers across each tortilla, pressing
        lightly into cream cheese.
      </span>,
      <span>
        Next layer enough
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices to cover each tortilla, followed by
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices.
      </span>,
      <span>
        Top with baby spinach, then roll up each tortilla tightly and place seal
        side down. Refrigerate the wraps until cool, about 1 hour.
      </span>,
      <span>Slice each wrap into 6 pieces and serve on a platter.</span>,
    ],
    more_recipe: [
      "salame-pepperoni-charcuterie-plate",
      "pepperoni-flatbread-pizza",
      "italian-sandwich",
      "italian-bean-salad",
    ],
  },
  "salame-grilled-cheese": {
    recipeName: "salame grilled cheese",
    metaTitle: "Salame Grilled Cheese Recipe | Galileo® Salame",
    metaDescription:
      "A classic kid favorite filled with our Galileo® Salame. Bright, tangy, delicious, and perfect year-round.",
    recipeDescription: (
      <span>
        A classic kid favorite that’s perfect year-round. Bright, tangy, and
        simply delicious.
      </span>
    ),
    recipeIngredients: [
      <span>2 slices wheat or white bread</span>,
      <span>1 tbsp. unsalted butter</span>,
      <span>1 slice Swiss cheese</span>,
      <span>1 slice Cheddar cheese</span>,
      <span>
        6 to 8
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,
      <span>4 to 6 bread and butter pickle chips, optional</span>,
    ],
    recipeDirections: [
      <span>
        Butter one side of each piece of bread. Place one piece of bread, butter
        side down, in medium non-stick pan over medium high heat.
      </span>,
      <span>
        Place one slice of cheese followed by
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices and optional pickles on the bread. Then place other piece of
        cheese and top with other slice of bread, butter side out.
      </span>,
      <span>
        Cook sandwich on each side for 2 to 4 minutes or until cheese is melted
        and bread is toasted golden brown.
      </span>,
      <span>
        Cut in half diagonally and serve with potato chips or side of choice.
      </span>,
    ],
    more_recipe: [
      "salame-cheese-croissant-sandwich",
      "salame-calzone",
      "italian-sandwich",
      "italian-bean-salad",
    ],
  },
  "italian-bean-salad": {
    recipeName: "italian bean salad",
    metaTitle: "Italian Marinated Bean Salad Recipe | Galileo® Salame",
    metaDescription:
      "Bursting with bright flavor, our Italian Marinated Bean Salad recipe can hold its own next to the big guys at your next barbecue.",
    recipeDescription: (
      <span>
        A must have side that acts more like a main, this Italian marinated bean
        salad is bursting with bright and vibrant flavors.
      </span>
    ),
    recipeIngredients: [
      <span>
        15 to 20
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices, diced
      </span>,
      <span>
        15 to 20
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, diced
      </span>,
      <span>1-15 oz can chickpeas, drained and rinsed</span>,
      <span>1-15 oz can cannellini beans, drained and rinsed</span>,
      <span>1/2 red bell pepper, diced</span>,
      <span>1/2 green bell pepper, diced</span>,
      <span>1/3 cup feta cheese</span>,
      <span>1/4 cup Italian dressing, store bought or homemade</span>,
      <span>1/2 tsp salt</span>,
    ],
    recipeDirections: [
      <span>
        In a large bowl mix all ingredients together. Cover and place in
        refrigerator for at least an hour before serving.
      </span>,
    ],
    more_recipe: [
      "italian-salame-bean-soup",
      "salame-pasta-salad",
      "antipasto-salame-sandwich",
      "italian-sandwich",
    ],
  },
  "italian-salame-bean-soup": {
    recipeName: "HEARTY ITALIAN SALAME AND BEAN SOUP",
    metaTitle: "Hearty Italian Salame & Bean Soup Recipe | Galileo® Salame",
    metaDescription:
      "This Hearty Italian Salame and Bean Soup made with Galileo® Salame is the perfect way to warm up any chilly evening with a flavorful meal.",
    recipeDescription: (
      <span>
        Perfect for dinner on a chilly evening, this hearty Italian soup made
        with Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame will warm you up from the inside out.
        Rich and full of flavor.
      </span>
    ),
    recipeIngredients: [
      <span>2 Tbsp butter</span>,
      <span>1/2 yellow onion, diced</span>,
      <span>1 tsp. garlic, minced</span>,
      <span>4 oz. ground Italian sausage</span>,
      <span>4 cups sodium free chicken stock</span>,
      <span>1 lb. or 2 cups red baby potatoes, quartered</span>,
      <span>1-15 oz. can white northern beans, drained and rinsed</span>,
      <span>
        20{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, quartered or 1 cup of{" "}
        <Link to="/our-products/italian-dry-salame-chubs/" className="gagl-ric-highlight">
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame Chub
        </Link>
        , diced
      </span>,
      <span>3 cups chopped fresh spinach</span>,
      <span>1/2 cup Parmesan, finely shredded salt and pepper</span>,
      <span>1/2 tsp. red pepper flakes, optional </span>,
    ],
    recipeDirections: [
      <span>
        Melt butter in soup pot over medium heat, add in onions and cook for 5
        minutes or until translucent. Add garlic and cook for 1 minute.
      </span>,
      <span>
        Add Italian sausage and cook for another 5 minutes, then slowly add in
        chicken broth. Bring to a boil over high heat.
      </span>,
      <span>
        Add in potatoes and return to a low simmer. Cook for 15 minutes or until
        potatoes are soft or fork tender.
      </span>,
      <span>
        Stir in beans, salame and spinach. Season with salt, pepper, and crushed
        red pepper for an extra kick. Let cook 5 more minutes, then serve and
        top with Parmesan cheese.
      </span>,
    ],
    more_recipe: [
      "salame-calzone",
      "cheesy-salame-pepperoni-pasta-bake",
      "italian-sandwich",
      "pizza-monkey-bread",
    ],
  },
  "salame-pasta-salad": {
    recipeName: "SALAME PASTA SALAD",
    metaTitle: "Salame Pasta Salad Recipe | Galileo® Salame",
    metaDescription:
      "Add some new zing to an old favorite. Our Galileo® Salame pasta salad recipe is the perfect side dish at your next party!",
    recipeDescription: (
      <span>
        Every cookout has an amazingly tasty yet simple pasta salad. Add some
        zing and flavor to your party favorite with Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame!
      </span>
    ),
    recipeIngredients: [
      <span>2 cups rotini pasta</span>,
      <span>
        4 oz.{" "}
        <Link to="/our-products/italian-dry-salame-chubs/" className="gagl-ric-highlight">
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame Chub
        </Link>
        , diced
      </span>,
      <span>1/2 cup grape or cherry tomatoes, quartered</span>,
      <span>1/2 cup red and green bell peppers, diced</span>,
      <span>1/2 cup red onion, thinly sliced</span>,
      <span>1/3 cup sliced black olives</span>,
      <span>1/3 cup Mozzarella cheese, cubed or shredded</span>,
      <span>1/2 cup Italian dressing</span>,
    ],
    recipeDirections: [
      <span>Cook pasta according to packaging.</span>,
      <span>
        Place cooked pasta, diced{" "}
        <Link to="/our-products/italian-dry-salame-chubs/" className="gagl-ric-highlight">
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame Chub
        </Link>
        , bell peppers, onions, tomatoes, olives, and dressing into a large bowl
        and mix well.
      </span>,
      <span>Cover and refrigerate for at least an hour before serving.</span>,
      <span>Garnish with cubed or shredded Mozzarella and serve.</span>,
    ],
    more_recipe: [
      "italian-bean-salad",
      "italian-salame-bean-soup",
      "pepperoni-mixed-greens-salad",
      "cheesy-salame-pepperoni-pasta-bake",
    ],
  },
  "pepperoni-mixed-greens-salad": {
    recipeName: "PEPPERONI MIXED GREENS SALAD",
    metaTitle: "Pepperoni Mixed Greens Salad Recipe | Galileo® Salame",
    metaDescription:
      "Perfect for picnics or for on-the-go, a salad topped with Galileo® Pepperoni makes any meal great!",
    recipeDescription: (
      <span>
        Change up your salad routine with this bright and zesty green salad
        featuring delicious Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni. A great start to an
        even better meal.
      </span>
    ),
    recipeIngredients: [
      <span>2 cups mixed greens salad mix</span>,
      <span>1/2 cup cherry tomatoes, halved</span>,
      <span>1/4 cup red onion, thinly sliced</span>,
      <span>
        7-9{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        &nbsp;slices, halved 4 oz. , diced
      </span>,
      <span>1/4 cup Provolone cheese, freshly grated</span>,
      <span>1/4 cup balsamic vinaigrette dressing</span>,
      <span>salt and pepper</span>,
    ],
    recipeDirections: [
      <span>
        Place greens, chopped veggies,{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        &nbsp;slices, and dressing into a large bowl and mix.
      </span>,
      <span>
        Season with black pepper and garnish with freshly grated Provolone
        cheese.
      </span>,
    ],
    more_recipe: [
      "pepperoni-hoagie",
      "pepperoni-flatbread-pizza",
      "salame-pasta-salad",
      "italian-bean-salad",
    ],
  },
  "pizza-monkey-bread": {
    recipeName: "PIZZA MONKEY BREAD",
    metaTitle: "Pizza Monkey Bread Recipe | Galileo® Salame",
    metaDescription:
      "Filled with gooey cheese and zesty Galileo® Salame and Pepperoni, this pull-apart bread will win over both kids and adults.",
    recipeDescription: (
      <span>
        Packed full of cheesy goodness and zesty Galileo<sup className="gagl-rcp-sup-text">®</sup> Salame and
        Pepperoni, this pull-apart bread is the perfect finger food snack
        guaranteed to win over both kids and adults!
      </span>
    ),
    recipeIngredients: [
      <span>2 cans refrigerated flakey biscuit dough</span>,
      <span>1/4 cup olive oil</span>,
      <span>2 tbsp. butter, melted</span>,
      <span>1 tsp. garlic powder</span>,
      <span>1 tsp. Italian seasoning</span>,
      <span>
        30{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup>
          Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>
        30{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          &nbsp;Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame{" "}
        </Link>
        slices , quartered
      </span>,
      <span>3 cups shredded Mozzarella cheese</span>,
      <span>1/3 cup marinara, for dipping</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 350 degrees. Spray Bundt pan with baking spray.
      </span>,
      <span>
        In a small bowl, combine olive oil, melted butter, Italian seasoning,
        and garlic powder. Stir to combine.
      </span>,
      <span>
        Quarter the biscuits and shape each piece into a round ball. In a large
        bowl, mix biscuit dough balls,{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices,
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup>
          Italian Dry Salame{" "}
        </Link>{" "}
        quarters, and Mozzarella cheese with 1/3 of the butter mixture.
      </span>,
      <span>
        Place into Bundt pan, ensuring the pepperoni and salame are evenly
        distributed throughout. Bake for 35 to 40 minutes.
      </span>,
      <span>
        Remove from oven and allow to cool for about 5 minutes, then flip bread
        out onto serving dish or cutting board. Brush bread all over with the
        remaining butter mixture and serve with warm marinara.
      </span>,
    ],
    more_recipe: [
      "cheesy-salame-pepperoni-pasta-bake",
      "hot-pizza-dip-garlic-crostini",
      "pepperoni-rolls",
      "meat-lovers-calzone",
    ],
  },
  "antipasto-skewers": {
    recipeName: "ANTIPASTO SKEWERS",
    metaTitle: "Antipasto Skewers Recipe | Galileo® Salame",
    metaDescription:
      "The perfect party appetizer that looks and tastes great! These flavor packed antipasto skewers will have your guests begging for more.",
    recipeDescription: (
      <span>
        Who doesn’t love a party appetizer that looks fancy but comes together
        in minutes! These quick and easy antipasto skewers with Galileo
        <sup className="gagl-rcp-sup-text">®</sup> Salame will have your guests begging for more.
      </span>
    ),
    recipeIngredients: [
      <span>1 cup cherry tomatoes</span>,
      <span>1 cup fresh basil</span>,
      <span>
        15 to 20{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,
      <span>1 cup fresh baby Mozzarella balls (bocconcini)</span>,
      <span>1/3 cup prepared Italian vinaigrette</span>,
    ],
    recipeDirections: [
      <span>
        Build skewers starting with cherry tomato, followed by folded fresh
        basil, 1 folded{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slice, and 1 baby Mozzarella ball.
      </span>,
      <span>
        For shorter skewers, stop there. For longer skewers, repeat the sequence
        1 or 2 more times.
      </span>,
      <span>
        Arrange skewers on a serving platter and lightly drizzle with Italian
        vinaigrette. Serve with remaining Italian vinaigrette on the side.
      </span>,
    ],
    more_recipe: [
      "salame-chips-honey-mustard-dip",
      "salame-pepperoni-charcuterie-plate",
      "italian-pinwheels",
      "antipasto-salame-sandwich",
    ],
  },
  "pepperoni-rolls": {
    recipeName: "PEPPERONI ROLLS",
    metaTitle: "Pepperoni Rolls Recipe | Galileo® Salame",
    metaDescription:
      "Bursting with flavor in every bite! These pepperoni rolls are perfect for a laid-back Friday night or a snack you'll keep coming back to.",
    recipeDescription: (
      <span>
        These pepperoni rolls are bursting with flavor in every bite. Great for
        a laid-back Friday night or a deliciously savory snack for the family.
      </span>
    ),
    recipeIngredients: [
      <span>1 tsp. rapid-rise yeast</span>,
      <span>3 cups all-purpose flour</span>,
      <span>2 tsp. coarse salt</span>,
      <span>1 cup warm water</span>,
      <span>2 tbsp. olive oil, plus a little extra for the bowl</span>,
      <span>
        30{" "}
        <Link
          to="/our-products/sliced-pepperoni//"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>1 cup shredded Mozzarella cheese</span>,
      <span>1/4 cup grated Parmesan cheese</span>,
      <span>1 1/2 tsp. fresh oregano leaves</span>,
      <span>1 egg, beaten</span>,
    ],
    recipeDirections: [
      <span>
        For the dough: Add 1 1/2 cups flour, 2 tsp. salt and yeast in a bowl and
        stir. Add water and 2 tablespoons of olive oil. Mix, slowly adding the
        remaining flour until it becomes too thick to stir. Knead for 10
        minutes. Place dough in a greased bowl, cover and let rise for 1-2
        hours.
      </span>,
      <span>
        For the pepperoni roll: Preheat oven to 375°. Lightly grease baking
        sheet.
      </span>,
      <span>
        Roll dough out into a rectangle 1/4 inch thick. Layer&nbsp;
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        &nbsp;slices, Mozzarella and Parmesan cheese on the dough, leaving 1/2 inch on
        all sides. Garnish with oregano.
      </span>,
      <span>
        Roll up the dough lengthwise and cut in half. Pinch ends and seal. Place
        seam side down on prepared baking sheet and brush dough with beaten egg.
      </span>,
      <span>Bake 30-35 minutes until golden brown.</span>,
    ],
    more_recipe: [
      "meat-lovers-calzone",
      "pepperoni-mixed-greens-salad",
      "pepperoni-pizza",
      "pepperoni-cheese-bread",
    ],
  },
  "pepperoni-pizza": {
    recipeName: "PEPPERONI PIZZA",
    metaTitle: "Pepperoni Rolls Recipe | Galileo® Salame",
    metaDescription:
      "Bursting with flavor in every bite! These pepperoni rolls are perfect for a laid-back Friday night or a snack you'll keep coming back to.",
    recipeDescription: (
      <span>
        A fresh pizza is the best pizza. This recipe is sure to impress any
        family, friend, or guest at the table. Simply delicious!
      </span>
    ),
    recipeIngredients: [
      <span>1 lb. premade pizza dough</span>,
      <span>1/3 cup flour</span>,
      <span>2 tsp. extra virgin olive oil</span>,
      <span>1 cup pizza sauce</span>,
      <span>1 cup shredded Mozzarella</span>,
      <span>
        15 to 20{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>2 tsp. chopped oregano</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 425 degrees. Roll out the pizza dough on flat countertop using flour and rolling pin until dough is about 12 inches round. 
      </span>,
      <span>
        Place rolled out dough on ungreased pizza pan, and brush dough with extra virgin olive oil.
      </span>,
      <span>
        Top pizza with sauce, cheese, and{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices. Garnish with chopped oregano.
      </span>,
      <span>Bake in oven until golden brown, about 10-12 minutes.</span>,
      <span>
        Looking to change things up? Try this recipe with{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
         slices instead.
      </span>,
    ],
    more_recipe: [
      "pepperoni-flatbread-pizza",
      "pepperoni-cheese-bread",
      "meat-lovers-calzone",
      "pepperoni-rolls",
    ],
  },
  "pepperoni-flatbread-pizza": {
    recipeName: "PEPPERONI FLATBREAD PIZZA",
    metaTitle: "Pepperoni Pizza Recipe | Galileo® Salame",
    metaDescription:
      "Skip the freezer and make a mouth-watering pizza yourself! Our fresh pepperoni pizza recipe is guaranteed to impress.",
    recipeDescription: (
      <span>
        A crispy, light, and zesty pizza perfect for weeknight dinner, a weekend
        appetizer, or whenever you need a snack.
      </span>
    ),
    recipeIngredients: [
      <span>1 premade flatbread pizza crust</span>,
      <span>5 tbsp. olive oil</span>,
      <span>1 cup shredded Italian cheese blend</span>,
      <span>
        15 to 20{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>{" "}
        slices
      </span>,
      <span>1/4 cup sundried tomato, julienned</span>,
      <span>1/4 cup sliced black olives</span>,
      <span>1 cup arugula</span>,
    ],
    recipeDirections: [
      <span>
        Preheat oven to 450 degrees. Brush premade crust with olive oil.
      </span>,
      <span>
        Evenly top crust with the Italian cheese, followed by{" "}
        <Link
          to="/our-products/sliced-pepperoni/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Pepperoni
        </Link>
        &nbsp;slices, sundried tomatoes and olives.
      </span>,
      <span>
        Bake for 7 to 10 minutes or until crust is golden brown, and cheese is
        melted.
      </span>,
      <span>Top with fresh arugula, slice and serve.</span>,
    ],
    more_recipe: [
      "pepperoni-pizza",
      "meat-lovers-calzone",
      "pepperoni-rolls",
      "pepperoni-cheese-bread",
    ],
  },
  "salame-cheese-croissant-sandwich": {
    recipeName: "SALAME AND CHEESE CROISSANT SANDWICH",
    metaTitle: "Pepperoni Flatbread Pizza Recipe | Galileo® Salame",
    metaDescription:
      "Looking for a light and tasty snack for your next gathering? Try our pepperoni flatbread pizza recipe for an appetizer everyone will enjoy!",
    recipeDescription: (
      <span>
        This salame and cheese croissant sandwich is an easy way to add some
        European flair to an otherwise boring lunch. Light, fluffy and
        delicious!
      </span>
    ),
    recipeIngredients: [
      <span>1 store bought or freshly baked croissant roll</span>,
      <span>1 tbsp. Italian dressing</span>,
      <span>1 to 2 green leaf lettuce leaves</span>,
      <span>1 package of brie cheese, rind removed and sliced</span>,
      <span>
        6 to 8{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,
      <span>1/4 red onion, thinly sliced</span>,
    ],
    recipeDirections: [
      <span>
        Slice croissant through the middle leaving the back edge connected. Fold
        open without breaking and brush top and bottom with Italian dressing.
      </span>,
      <span>
        From the bottom up, layer lettuce, Brie cheese,{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, and onions.
      </span>,
      <span>
        For a hot sandwich, skip the lettuce and place the assembled sandwich
        into a 350-degree oven for 5-10 minutes or until the croissant is warmed
        through and cheese has started to melt.
      </span>,
    ],
    more_recipe: [
      "salame-grilled-cheese",
      "antipasto-salame-sandwich",
      "italian-pinwheels",
      "salame-calzone",
    ],
  },
  "antipasto-salame-sandwich": {
    recipeName: "ANTIPASTO SALAME SANDWICH",
    metaTitle: "Antipasto Salame Sandwich Recipe | Galileo® Salame",
    metaDescription:
      "Stacked with bright flavors of Italian antipasto, this sandwich with pesto and Italian dry Salame might just become a family favorite!",
    recipeDescription: (
      <span>
        This antipasto sandwich is stacked with flavor: from the layers of
        Italian dry salame to the bright pop of the pesto, peppers, and red
        onions. This sandwich might just become your new family favorite!
      </span>
    ),
    recipeIngredients: [
      <span>1 seeded Italian roll, sliced open</span>,
      <span>
        3 oz. pesto mayonnaise (mayo and prepared pesto, 1.5 oz. each)
      </span>,
      <span>
        6 to 8{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices
      </span>,
      <span>2 to 3 slices Provolone cheese</span>,
      <span>2 to 3 strips of roasted red pepper</span>,
      <span>1/4 red onion, sliced thin</span>,
      <span>1 to 2 green leaf lettuce leaves</span>,
    ],
    recipeDirections: [
      <span>
        Mix pesto and mayo together and evenly spread mixture inside the bread
        roll.
      </span>,
      <span>
        From the bottom, layer{" "}
        <Link
          to="/our-products/sliced-italian-dry-salame/"
          className="gagl-ric-highlight"
        >
          Galileo<sup className="gagl-rcp-sup-text">®</sup> Italian Dry Salame
        </Link>{" "}
        slices, cheese, peppers, onions, and lettuce.
      </span>,
      <span>
        To try this sandwich as a panini, assemble the sandwich without lettuce.
        Then melt 2 tbsp. butter in skillet over medium high heat and place
        sandwich into the hot pan. Cook each side, pressing down, for about 2-3
        minutes or until the bread is golden brown, and the cheese begins to
        melt.
      </span>,
    ],
    more_recipe: [
      "italian-sandwich",
      "italian-bean-salad",
      "pepperoni-hoagie",
      "salame-pasta-salad",
    ],
  },
};
